.react-datetime-picker__wrapper {
  border: none !important;
}

.react-datetime-picker__clear-button {
  display: none;
}
.react-calendar__tile--active {
  background-color: #6d28d9 !important;
  font-weight: 600;
}
