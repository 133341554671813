.react-date-picker__wrapper {
    border: none !important;
}

.react-date-picker__clear-button {
    display: none;
}
.react-calendar__tile--active {
    background-color: #6d28d9 !important;
    font-weight: 600;
}